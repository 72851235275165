import React from 'react'
import {Button} from './Button';
import './HeroSection.css';
import '../App.css';
import videos from '../videos/pasar2.gif';

function HeroSection() {
  return (
    <div className='hero-container'>
      <img src={videos} alt="Night Market in Singapore" />
      <h1 class="heroborder">Food Awaits</h1>
      <p class="heroborder">Mai Tu Liao</p>
      <div className="hero-btns">

        <Button className='btns' buttonStyle='btn--primary'
        buttonSize='btn--large'>FIND PASAR NOW!
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
/*        <Button className='btns' buttonStyle='btn--outline'
buttonSize='btn--large'>FIND PASAR
</Button>*/
