import {useEffect, useState, useCallback} from 'react'
import '../../App.css'
import '../Maps.css'
import { APIProvider, useMapsLibrary, Map, MapControl, ControlPosition, AdvancedMarker, Pin, InfoWindow, Marker, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import {Link} from "react-router-dom";
import axios from 'axios'

const google = window.google;
var map;



export function checkLogo(orgName){    //Assign respective logo to maps
  if(orgName=="Taiwan Night Market"){
    return "/PasarOrgs/SingaNightBazaar.jpg"
  }
  else if(orgName=="KTL Lights"){
    return "/PasarOrgs/TLK-LnE.jpg"
  }
  else {
    return  "/PasarOrgs/PASAR.png"
  }
  }

export default function Pasar() {
  const [open, setOpen] = useState(false);//draw location information from database.
  const [selected, setSelected]=useState(null);//this is for autocomplete
  const [markerRef, marker] = useAdvancedMarkerRef();
  const[pasarlocations, setPasarLocation] = useState([]);//mongodb stuff
  useEffect(()=> {axios.get('https://mong1-591723011013.us-central1.run.app/getLatLngNameD').then(response=> setPasarLocation(response.data)).catch(err => console.log(err))},[]);//mongodb stuff to get latlng
  const[pasarinfo, setPasarInfo] = useState([]);
  useEffect(()=> {axios.get('https://mong1-591723011013.us-central1.run.app/locations').then(response=> setPasarInfo(response.data)).catch(err => console.log(err))},[]);//mongodb get location details
 // pasarlocations.map(item=> (item._id, position=item.pasarName, item.address, item.coordinates))
 const parser = JSON.stringify(pasarlocations);
 const latlng = JSON.parse(parser);  
 console.log(pasarinfo);
 var holdcord = {lat: 1.3521, lng: 103.8198};
 /*const latlngArray = [pasarlocations.length]; //NEW LOOP SHIT TO STORE POSITION VALUES SO MARKERS STOP HIDING, CHECK
 for(let i=0; i<pasarlocations.length; i++)  //NEW LOOP SHIT TO STORE POSITION VALUES SO MARKERS STOP HIDING, CHECK
 {
   latlngArray[i]=latlng[i];
   console.log(latlngArray[i])
 }*/

  const handleMarkerClick = useCallback( //open/close infowindow
  () => setOpen(isShown => !isShown)
  
);


 
const MarkerWithInfoWindow = props => {
  [markerRef, marker] = useAdvancedMarkerRef();
 }

 function createMarker(lat, lon) {  // TEST create new infowindow for each marker
  var newmarker = new google.maps.Marker({
      position: new google.maps.LatLng(lat, lon),
      map: map,
  });

  newmarker['infowindow'] = new google.maps.InfoWindow({
          content: "test"
      });

  google.maps.event.addListener(newmarker, 'mouseover', function() {
      this['infowindow'].open(map, this);
  });
} 
 
const handleClose = useCallback(() => setOpen(false), []);

    return (
<APIProvider apiKey={"AIzaSyDnlRTJSieyoYvoWg4zeEcXAZi6HMutLc8"} libraries={["maps","places"]}> 
  
<Map //map object with default controls
style={{width: '100vw', height: '90vh'}}
defaultCenter={{lat: 1.3521, lng: 103.8198}}
defaultZoom={13}
gestureHandling={'greedy'}
disableDefaultUI={true}
zoomControl={true}
rotateControl={true}
mapTypeControl={true} 
mapId={'98a42cfe1a78d3b0'}
reuseMaps={true}
>
{
latlng.map(item => (<AdvancedMarker
  position={item} onClick={handleMarkerClick} ref={markerRef}>
    <img src={checkLogo(item.organizer)} width={50} height={35} />
<Pin background={"red"} borderColor={"white"} glyphColor={"black"} scale={2}></Pin> 
  
  </AdvancedMarker>) ) 
}

{open && latlng.map(item => (<InfoWindow position={item} onClose={handleClose} > <p>{item.pasarName}</p> <p>{item.organizer}</p> <p>Stalls:{item.stalls.map(item =>(<p>{item}</p>))}</p> <Link to={"../pasarpages/"+item._id}><h1>Click for More!</h1></Link></InfoWindow>))/* Temporary Fix, open all infowindows or close all infowindows.
When I get better, fix to be able to open one by one. */} 



<MapControl position={ControlPosition.BLOCK_START_INLINE_CENTER}>



</MapControl>
</Map>
</APIProvider> 
    );
}

/*  



{
latlng.map(item => createMarker(item)) 

}

{open &&  <InfoWindow anchor={marker} onClick={handleMarkerClick} onCloseClick={() => setOpen(false)}><p>test it now</p></InfoWindow>}


<AdvancedMarker
position={latlng[0]} onClick={() => setOpen(true)}>
  <Pin background={"orange"} borderColor={"white"} glyphColor={"black"}></Pin>
  {console.log("marker")}
</AdvancedMarker>

<AdvancedMarker
position={latlng[1]} onClick={() => setOpen(true)}>
  <Pin background={"orange"} borderColor={"white"} glyphColor={"black"}></Pin>
</AdvancedMarker>



        <div className='pasar'> 
    <script type="module" src="../Maps.js"></script>
    <div id="map"></div>

    <script>{(g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})
      ({key: "AIzaSyDnlRTJSieyoYvoWg4zeEcXAZi6HMutLc8", v: "weekly"})}</script>
    <script
      src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDnlRTJSieyoYvoWg4zeEcXAZi6HMutLc8&callback=initAutocomplete&libraries=places&v=weekly"
      defer
    ></script>
        </div>


    <APIProvider apiKey={'AIzaSyDnlRTJSieyoYvoWg4zeEcXAZi6HMutLc8'} libraries={["maps","places"]}>

  
        <Map //map object with default controls
      style={{width: '100vw', height: '90vh'}}
      defaultCenter={{lat: 1.3521, lng: 103.8198}}
      defaultZoom={12}
      gestureHandling={'greedy'}
      disableDefaultUI={true}
      zoomControl={true}
      rotateControl={true}
      mapTypeControl={true} 
    >
  <CustomMapControl position={ControlPosition.BLOCK_START_INLINE_CENTER}>
      <script type="module" src="./Maps.js" async />
      <input
      id="pac-input"
      className="controls"
      type="text"
      placeholder="Search Box"
    />
       <button className="button-inverse h-12 w-full self-end" type="submit">Search</button>
    </CustomMapControl>
        </Map>
        </APIProvider> 

//GOOGLE MAPS SEARCHER, SEE IF WANNA INCLUDE OR NOT

import usePlacesAutocomplete, {getGeocode, getLatLng} from "use-places-autocomplete";//can possibly remove. No need to search google maps itself.
import {Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption} from "@reach/combobox"; //can possibly remove. No need to search google maps itself.
import "@reach/combobox/styles.css"//can possibly remove. No need to search google maps itself.
const PlacesAutocomplete = ({setSelected}) => {//can possibly remove. No need to search google maps itself.
  const {ready,value,setValue,suggestions:{status,data},clearSuggestions,} = usePlacesAutocomplete();
  let places = [];

    const handleSelect = async (address) => {
      setValue(address, false);
      clearSuggestions();

      const results = await getGeocode({address});
      const {lat, lng} = await getLatLng(results[0]);
      setSelected({lat, lng});
    };

  return(
  <Combobox onSelect={handleSelect}>
      <ComboboxInput value = {value} 
    onChange={e=>setValue(e.target.value)} disabled = {!ready} className="combobox-input" placeholder="Search for Pasar" />
      <ComboboxPopover>
        <ComboboxList>
        {status ==="OK" && data.map(({place_id, description}) => <ComboboxOption key={place_id}  value={description} />)}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
<PlacesAutocomplete setSelected={setSelected} />
{selected && <Marker position={selected} />}
  )

}

*/
