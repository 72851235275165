import axios from 'axios'
import {useEffect, useState, useCallback} from 'react'
import {useParams,Link} from "react-router-dom";
import '../../App.css'



export const Pasarpage = () => {

    const[pasarinfo, setPasarInfo] = useState([]);
    useEffect(()=> {axios.get('https://mong1-591723011013.us-central1.run.app/locations').then(response=> setPasarInfo(response.data)).catch(err => console.log(err))},[]);//mongodb get location details
    const parser = JSON.stringify(pasarinfo);
    const latlng = JSON.parse(parser);

    return (//dynamic routing to create Pages for each Pasar ID
        <div>
           {latlng.map(item => (<Link to={item._id}><h1>{item.pasarName}</h1></Link>))}
        </div>
        );
  }

  export default Pasarpage;
