import {React, useState, useEffect} from 'react'
import '../../App.css'
import axios from 'axios'
import { APIProvider, useMapsLibrary, Map, MapControl, ControlPosition, AdvancedMarker, Pin, InfoWindow, Marker, LatLng } from '@vis.gl/react-google-maps';


export default function About() {
   // return <h1 className='about'>About Us</h1>;
   
  // pasarlocations.forEach((test)=>console.log(test.toString()));
 
return (
    <div>
        <div class="background-image" />
    <div>
        <h1>About PASAR!</h1>
        
      <h2>I've always loved taking strolls through Pasar Malams in Singapore, and given it's nature to move around, sometimes I have to leave it all up to luck to find one.</h2>
      <h2>I've also found it difficult to track my favourite stalls that stand out compared to the general Pasar Malam cuisines.</h2>
      <h2>In the end, I opted to create this site for both myself and fellow Pasar Malam enjoyers. I hope to update the site and make it a good source of infomation for everyone.</h2>
        <br/>
        <h2>Adding this site to your Favourites/Bookmarks is greatly appreciated!</h2>
      </div>
      <img src={require('.//images/img-home.jpg')} alt="" style={{marginLeft: 300, marginRight: 300}}></img>
    </div>
  );


   /*
    useEffect(()=> {const fetchPosts = async() => {
    const response = await fetch('http://localhost:5000/locations');
    const PasarLocation = await response.json();
    getPasarLocation(PasarLocation);

    fetchPosts();
   }},[]);n   
   
   
   const [name, setName] = useState("");
   const [email, setEmail] = useState("");
   const handleOnSubmit = async (e) => {
       e.preventDefault();
       let result = await fetch(
       'http://localhost:5000/register', {
           method: "post",
           body: JSON.stringify({ name, email }),
           headers: {
               'Content-Type': 'application/json'
           }
       })
       result = await result.json();
       console.warn(result);
       if (result) {
           alert("Data saved succesfully");
           setEmail("");
           setName("");
       }
   }
   return (
       <>
           <h1>This is React WebApp </h1>
           <form action="">
               <input type="text" placeholder="name"
               value={name} onChange={(e) => setName(e.target.value)} />
               <input type="email" placeholder="email"
               value={email} onChange={(e) => setEmail(e.target.value)} />
               <button type="submit"
               onClick={handleOnSubmit}>submit</button>
           </form>

       </>
   );*/

}