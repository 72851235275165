import './App.css';
import Navbar from './components/Navbar';
import React from 'react';
import {BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Pasar from './components/pages/Pasar';
import PasarDetails from './components/pages/PasarDetails';
import PasarPages from './components/pages/PasarPages';
import Signup from './components/pages/SignUp';

function App() {
  return (
    <>
    <Router>
      <Navbar />
        <Routes>
          <Route path='/' exact element={<Home />}/>
          <Route path='/about' exact element={<About />}/>
          <Route path='/pasar' exact element={<Pasar />}/>
          <Route path='/pasarpages' element={<PasarPages />}/>
          <Route path='/pasarpages/:pasarId' element={<PasarDetails />}/>
          <Route path='/signup' exact element={<Signup />}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
