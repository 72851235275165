import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../../App.css";

const ProductDetails = () => {
   const [pasarinfo, setPasarInfo] = useState([]);
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState(null);

   useEffect(() => {
      axios.get('https://mong1-591723011013.us-central1.run.app/locations')
         .then(response => {
            setPasarInfo(response.data);
            setLoading(false);
         })
         .catch(err => {
            console.error(err);
            setError('Failed to fetch data');
            setLoading(false);
         });
   }, []);

   if (loading) {
      return <p>Loading...</p>;
   }

   if (error) {
      return <p>{error}</p>;
   }

   const getCurrentPasar = () => {
      const pasarId = window.location.pathname;
      //console.log(pasarId)
      const getCurrentPasar = pasarId.substring(pasarId.lastIndexOf('/')+1);
      //console.log(getCurrentPasar)
      return getCurrentPasar; 
   };

   const parser = JSON.stringify(pasarinfo);
   const latlng = JSON.parse(parser);

   const test = getCurrentPasar();
   const result = latlng.find(({ _id }) => _id === test);

   return (
      <div class="detailsborder">
         {result ? (
            <>
               <h1>Pasar Malam at {result.venue} by {result.organizer}</h1>
               <p class="center">Pasar Name: {result.pasarName}</p>
               <p class="center">Pasar Address: {result.address}</p>
               <p class="center">Duration: From {result.StartDate.substring(0,10)} to {result.EndDate.substring(0,10)}</p><br/>
               <p class="center">List of Stalls: </p>
               {result.stalls.map((item, index) => <ul class="center"><li key={index}>{item}</li></ul>)}
            </>
         ) : (
            <p>Pasar not found</p>
         )}
      </div>
   );
};

export default ProductDetails;
