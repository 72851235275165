import '../../App.css';
import HeroSection from '../HeroSection';
import React from 'react';
import Cards from '../Cards';

function Home() {
    return (
      <>
        <HeroSection />
        <Cards />
      </>
    )
}

export default Home;