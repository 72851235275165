import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CardItem from './CardItem'; 

function checkLogo(orgName){    //Assign respective logo to maps
  if(orgName=="Taiwan Night Market"){
    return "/PasarOrgs/SingaNightBazaar.jpg"
  }
  else if(orgName=="KTL Lights"){
    return "/PasarOrgs/TLK-LnE.jpg"
  }
  else {
    return  "/PasarOrgs/PASAR.png"
  }
  }

function Cards() {
    const [pasarinfo, setPasarInfo] = useState([]);

    useEffect(() => {
        axios.get('https://mong1-591723011013.us-central1.run.app/locations')
            .then(response => setPasarInfo(response.data))
            .catch(err => console.log(err));
    }, []); // Empty dependency array ensures this runs once on mount

    return (
        <div className='cards'>
            <h1>Check out these Active Pasar Malams!</h1>
            <div className="cards__container">
                <div className="cards__wrapper">
                    {pasarinfo.length > 0 ? (
                        pasarinfo.map(item => (
                            <ul key={item._id} className="cards__items">
                                <CardItem 
                                    src={checkLogo(item.organizer)}
                                    text={item.pasarName}
                                    label="Food"
                                    path={`../pasarpages/${item._id}`}
                                />
                            </ul>
                        ))
                    ) : (
                        <p>No data available</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Cards;
