import React from 'react'
import '../../App.css'



export default function Signup() {
   // return <h1 className='about'>About Us</h1>;
   
  // pasarlocations.forEach((test)=>console.log(test.toString()));
 
return (
    <div>
        <div class="background-image" />
    <div>
        <h1>Get Notified!</h1>
        <br />
      <h2>We're still developing this, but if you would like to be notified when a new Pasar Malam is Scheduled, you can enter your email here!</h2>
      </div>
      <form action="/notdone.php">
    <label for="email">Email:</label>
    <input type="text" id="fname" name="fname" /> <br />
    <input type="submit" value="Submit" />
        </form> 
    </div>
  );
}